<template>
	<div class="firstForm">
		<Header></Header>
		<div class="wrapper">
			<el-breadcrumb separator="/" style="margin-top: 20px;">
				<el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
				<el-breadcrumb-item :to="{ path: '/carId' }">{{number}}</el-breadcrumb-item>
				<el-breadcrumb-item>养护信息</el-breadcrumb-item>
			</el-breadcrumb>
			<div class="form-wrapper shadow" style="padding: 30px;">
				<div class="main-title">车牌号：{{number}}</div>
				<el-form :model="check" :rules="rules" ref="ruleForm" label-width="" class="demo-ruleForm">
					<el-form-item label="" prop="checklist" style="margin-bottom: 0;">
						<div class="form-title"><i></i>车辆信息</div>
						<el-row style="margin: 0 0 20px 0;">
							<el-col :span="3">车架号:</el-col>
							<el-col :span="7">
								<el-input v-model="check.car.vin" placeholder="车架号" readonly></el-input>
							</el-col>
							<el-col class="line" style="margin-left: 10px;" :span="1">&nbsp;</el-col>
							<el-col :span="3">车牌号:</el-col>
							<el-col :span="7">
								<el-input v-model="check.car.number" placeholder="车牌号" readonly></el-input>
							</el-col>
						</el-row>
						<el-row style="margin: 0 0 20px 0;">
							<el-col :span="3">所属物流公司:</el-col>
							<el-col :span="7">
								<el-input :value="check.fleet ? check.fleet.name : '无'" placeholder="所属物流公司" readonly></el-input>
							</el-col>
							<el-col class="line" style="margin-left: 10px;" :span="1">&nbsp;</el-col>
							<el-col :span="3">当前车辆里程数:</el-col>
							<el-col :span="7">
								<el-input v-model="check.mileage" placeholder="当前车辆里程数"></el-input>
							</el-col>
							<el-col class="line" style="margin-left: 10px;" :span="2">KM</el-col>
						</el-row>
					</el-form-item>
					<el-form-item label="" prop="checklist" style="margin-bottom: 0;">
						<div class="form-title"><i></i>整车及设备照片</div>
						<el-upload :file-list="check.upfiles[1]" disabled list-type="picture-card"
							:on-preview="handlePictureCardPreview">
						</el-upload>
						<el-dialog :visible.sync="dialogVisible">
							<img width="100%" :src="dialogImageUrl" alt="">
						</el-dialog>
					</el-form-item>
					<el-form-item label="" prop="fleet" v-for="(item, idx) in itemList" :key="idx">
						<div class="form-title"><i></i>{{item.name}}</div>
						<el-col :span="14">
							<el-upload v-if="idx != 2" list-type="picture-card" :on-preview="handlePictureCardPreview"
								:file-list="check.details[idx].upfiles" disabled>
							</el-upload>
							<video class="video-small" v-if="idx == 2 && check.details[idx].upfiles.length > 0"
								:src="check.details[idx].upfiles[0].url"
								@click="dialogVideoVisible = true">您的浏览器不支持,请更换浏览器。</video>
						</el-col>

						<el-dialog title="视频" v-if="idx == 2 && check.details[idx].upfiles.length > 0"
							:visible.sync="dialogVideoVisible" :append-to-body="true" width="80%" top="3%">
							<div class="video-dialog">
								<video :src="check.details[idx].upfiles[0].url"
									controls="controls">您的浏览器不支持,请更换浏览器。</video>
							</div>

						</el-dialog>
						<el-col :span="10">
							<el-radio-group v-model="check.details[idx].status">
								<el-radio :label="1" border>合格</el-radio>
								<el-radio :label="0" border>异常</el-radio>
							</el-radio-group>
							<div class="fill-in" v-if="check.details[idx].status==0">
								<el-input type="textarea" :autosize="{ minRows: 3, maxRows: 6}" placeholder="请输入维修建议"
									v-model="check.details[idx].remark">
								</el-input>
								<el-radio-group v-model="check.details[idx].needReplace">
									<el-radio :label="0" border>无需更换</el-radio>
									<el-radio :label="1" border>更换</el-radio>
								</el-radio-group>
							</div>
						</el-col>
					</el-form-item>
					<el-form-item label="" prop="repair" style="margin-bottom: 0;">
						<div class="form-title"><i></i>检查单：</div>
						<el-upload list-type="picture-card" :on-preview="handlePictureCardPreview"
							:file-list="check.upfiles[0]" disabled>
						</el-upload>
					</el-form-item>
                    <el-form-item label="" prop="repair" style="margin-bottom: 0;">
                        <div class="form-title"><i></i>养护件更换前照片：</div>
                        <el-upload list-type="picture-card" :on-preview="handlePictureCardPreview"
                                   :file-list="check.upfiles[2]" disabled>
                        </el-upload>
                    </el-form-item>
                    <el-form-item label="" prop="repair" style="margin-bottom: 0;">
                        <div class="form-title"><i></i>养护件更换后照片：</div>
                        <el-upload list-type="picture-card" :on-preview="handlePictureCardPreview"
                                   :file-list="check.upfiles[3]" disabled>
                        </el-upload>
                    </el-form-item>
                    <el-form-item label="" prop="repair" style="margin-bottom: 0;">
                        <div class="form-title"><i></i>结算单照片：</div>
                        <el-upload list-type="picture-card" :on-preview="handlePictureCardPreview"
                                   :file-list="check.upfiles[4]" disabled>
                        </el-upload>
                    </el-form-item>

                    <div class="form-title"><i></i>其他</div>
                    <el-row style="margin: 0 0 20px 0;">
                        <el-col :span="2">养护包内容:</el-col>
                        <el-col :span="20">
                            <el-input v-model="check.mplanContent" readonly></el-input>
                        </el-col>
                    </el-row>

                    <el-row style="margin: 0 0 20px 0;">
                        <el-form-item label="" prop="nextMileage1">
                            <el-col :span="2">下次保养里程:</el-col>
                            <el-col :span="7">
                                <el-input v-model="check.nextMileage1" type="number" placeholder="请输入下次养护里程数"></el-input>
                            </el-col>
                            <el-col class="line" style="margin-left: 10px;" :span="2">KM</el-col>
                        </el-form-item>
                    </el-row>
                    <el-row style="margin: 0 0 20px 0;">
                        <el-col :span="2">维修内容:</el-col>
                        <el-col :span="20">
                            <el-input v-model="check.service"></el-input>
                        </el-col>
                    </el-row>
                    <el-row style="margin: 0 0 20px 0;">
                        <el-col :span="2">备注:</el-col>
                        <el-col :span="20">
                            <el-input v-model="check.remark"></el-input>
                        </el-col>
                    </el-row>
                    <el-form-item class="form-btn">
                        <el-button type="primary" @click="prev()">返回</el-button>
                        <el-button v-if="check.status == 1" type="primary" @click="submitForm('ruleForm')">提交
                        </el-button>
                    </el-form-item>
				</el-form>
			</div>
		</div>
		<Footer></Footer>
	</div>
</template>

<script>
	import http from '../../http.js'
	import Header from "../../components/header.vue"
	import Footer from "../../components/footer.vue"
	export default {
		name: 'Home',
		components: {
			Header,
			Footer
		},
		data() {
			return {
				dialogVideoVisible: false,
				itemList: [],
				check: {},
				number: '',
				dialogImageUrl: '',
				dialogVisible: false,
                rules: {
                    nextMileage1: [{
                        required: true,
                        message: "请输入下次保养里程",
                        trigger: "blur"
                    }],
                }
			}
		},
		created() {
			this.getCheckItemList()
			if (this.$route.query.id) {
				this.check.id = this.$route.query.id
				this.getCheck()
			}
			if (this.$route.query.number) {
				this.number = this.$route.query.number
			}
		},
		methods: {
			getCheckItemList() {
				http.fetchGet('/api/v2/admin/car-check/item/list').then(res => {
					this.itemList = res.data.data
				})
			},
			getCheck() {
				http.fetchGet('/api/admin/mplan/car_check', {
					id: this.check.id
				}).then((res) => {
					if (res.data.code == 0) {
						this.check = res.data.data
                        this.check.mileage = this.check.car.mileage
                        this.check.mplanContent = this.check.car.mplanContent
                        if(this.check.mileage == 0){
                            this.check.mileage = ''
                        }

						this.check.details.forEach(item => {
							item.upfiles = []
							item.files.forEach(file => {
								item.upfiles.push({
									url: file.file
								})
							})
						})
						this.check.upfiles = [
                            [], [], [], [], []
                        ]
						if (this.check.files) {
							this.check.files.forEach(item => {
								this.check.upfiles[item.itemId * -1 - 1].push({
									url: item.file
								})
							})
						}
					} else {
						this.$message.error(res.data.desc)
					}
				})
			},
			prev() {
				this.$router.go(-1);
			},
			handleRemove(file, fileList) {
				console.log(file, fileList);
			},
			handlePictureCardPreview(file) {
				this.dialogImageUrl = file.url;
				this.dialogVisible = true;
			},
			submitForm(formName) {
				let app = this
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        this.$confirm('请确认信息已录入完成, 是否确认保存?', '提示', {
                            confirmButtonText: '确定',
                            cancelButtonText: '取消',
                            type: 'warning'
                        }).then(() => {
                            app.check.typeIn = 1
                            http.fetchPost('/api/admin/mplan/car_check', app.check).then(res => {
                                if (res.data.code == 0) {
                                    app.$message.success('保存成功')
                                } else {
                                    app.$message.error(res.data.desc)
                                }
                            })
                        })
                    }
                })
			}
		},
	}
</script>
<style lang="scss">
	.el-upload--picture-card {
		display: none;
	}

	.el-upload-list__item-status-label {
		display: none !important;
	}
</style>
<style lang="scss" scoped="scoped">
	.video-dialog{
		width: 100%;
		text-align: center;
		background: #000;
		video{
			max-height:700px;
			max-width: 100%;
		}
	}
</style>
